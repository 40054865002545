<template>
  <b-popover
    ref="maxQuotaPopover"
    :target="target"
    placement="bottom"
    custom-class="set-width max-quota-popover"
    :show="user.loggedIn && !getCanSaveMoreDesign"
    variant="warning"
  >
    <template #title>Thông báo</template>
    Bạn chỉ được phéo lưu tối đa <b>5</b> thiết kế.<br />
    Để lưu thiết kế này, bạn hãy xóa bớt mẫu đã lưu trong thư viện thiết kế của
    bạn
    <router-link :to="{ name: 'customer_design' }"> ở đây </router-link>.
    <div class="mt-2">
      <b-button
        class="float-right mb-2"
        variant="outline-secondary"
        size="sm"
        @click="onClose"
        >Đóng</b-button
      >
    </div>
  </b-popover>
</template>
<style scoped>
.max-quota-popover a:hover {
  color: #29aae2;
}
.max-quota-popover.set-width {
  min-width: 300px;
}
</style>
<script>
import { BPopover, BButton } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

const moduleName = 'designModule';

export default {
  name: 'MaxQuotaPopover',
  components: {
    BPopover,
    BButton
  },
  props: ['target'],
  computed: {
    ...mapGetters({
      getCanSaveMoreDesign: `${moduleName}/getCanSaveMoreDesign`,
      user: 'user'
    })
  },
  methods: {
    onClose() {
      this.$refs.maxQuotaPopover.$emit('close');
    }
  }
};
</script>
