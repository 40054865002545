import { fabric } from 'fabric';
import 'fabric-history';
import {
  BButton,
  BContainer,
  BCol,
  BRow,
  BModal,
  BTooltip,
  BCollapse,
  BFormSelect,
  BFormSelectOption,
  BFormInput
} from 'bootstrap-vue';
import * as Sentry from '@sentry/vue';
import { RequestFactory } from '@Request/RequestFactory';
import { canvasHelpers, productHelpers, constants, eventBus } from '@/utils';
import { imgListData, defaultFontList } from '@/utils/mediaData';
import {
  isPC,
  isTablet,
  isMobile,
  isHorizontalScreen,
  isPCSmallScreen,
  subStringProduct,
  isShowMainDesignArea
} from '@/utils/commonUtils';
import HeaderPc from './components/Header/Pc/HeaderPc.vue';
import HeaderMb from './components/Header/Mobile/HeaderMb.vue';
import MainToolbarPc from './components/MainToolbar/Pc/MainToolbarPc.vue';
import MainToolbarMb from './components/MainToolbar/Mobile/MainToolbarMb.vue';
import ActionToolbarPc from './components/ActionToolbar/Pc/ActionToolbarPc.vue';
import ActionToolbarMb from './components/ActionToolbar/Mobile/ActionToolbarMb.vue';
import MainDesignArea from './components/MainDesignArea/MainDesignArea.vue';
import CanvasRatioDialog from './components/CanvasRatioDialog/CanvasRatioDialog.vue';
import SupportBlock from '../components/SupportBlock/SupportBlock.vue';
import {
  SET_FONT_LIST,
  CHANGE_PRODUCT,
  SET_ACTIVE_ITEM_BY_PRODUCT_ID,
  SET_APP_ELEMENT,
  SET_CAN_SAVE_MORE_DESIGN
} from '@/store/designModule';
import { mapGetters } from 'vuex';

const moduleName = 'designModule';
const CustomerRequest = RequestFactory.get('customers');
const ProductRequest = RequestFactory.get('products');

export default {
  name: 'Design',
  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BModal,
    BTooltip,
    BCollapse,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    HeaderPc,
    HeaderMb,
    MainToolbarPc,
    MainToolbarMb,
    ActionToolbarPc,
    ActionToolbarMb,
    MainDesignArea,
    CanvasRatioDialog,
    SupportBlock
  },
  data() {
    return {
      isLoading: true,
      isOpenSidebar: true,
      isHideMainToolbar: false,
      constants,
      isPC,
      isTablet,
      isMobile,
      isHorizontalScreen,
      isPCSmallScreen,
      isShowMainDesignArea,
      globalCanvas: {},
      imageExportInfo: {
        image: null,
        imageOptimize: null,
        json: {}
      },
      categoryList: null,
      imageList: null,
      sendMailErrorCount: 0,
      selectedZoomPercent: 0,
      deliveryModeValue: 1,
      zoomCount: 0
    };
  },
  computed: {
    ...mapGetters({
      getCanvasList: `${moduleName}/getCanvasList`,
      getStatusEditCanvas: `${moduleName}/getStatusEditCanvas`
    })
  },
  methods: {
    async fetchFonts() {
      try {
        const store = this.$store;
        const { data } = await ProductRequest.getFonts();
        const { response = [] } = data || {};

        const fetchedFonts = response.map(font => ({
          name: font.name,
          fontFamily: font.fontFamily,
          isDefault: false
        }));
        const fontList = [...defaultFontList, ...fetchedFonts];
        const totalFetchedFonts = response.length;

        /**
         * Save all fonts to store
         */
        store.commit(`${moduleName}/${SET_FONT_LIST}`, {
          fontList
        });

        /**
         * Insert font-faces to document
         */
        for (let index = 0; index < totalFetchedFonts; index++) {
          const font = response[index];
          this.addFont(font);
        }
      } catch (error) {
        Sentry.captureException(error);
        alert('Can not load font');
      }
    },
    async fetchAllAppData() {
      // const store = this.$store;
      const loader = this.$loading.show();

      await this.fetchFonts();
      await this.initStoreData();
      await this.initCategoryElement();

      this.$nextTick(function() {
        loader.hide();
      });
    },
    onOpenSidebar: function(e) {
      this.isOpenSidebar = e;
    },
    forceCloseSidebarAndToolbar: function() {
      this.isOpenSidebar = false;
      this.isHideMainToolbar = false;
    },
    onHideMainToolbar: function(e) {
      this.isHideMainToolbar = e;
    },
    addFont: function(font) {
      if (font) {
        const myfont = new FontFace(font.fontFamily, `url(${font.url})`);
        myfont
          .load()
          .then(loadedFont => {
            document.fonts.add(loadedFont);
            document.body.style.fontFamily = font.fontFamily;
          })
          .catch(function(error) {
            Sentry.captureException(error);
          });
      }
    },
    async initStoreData() {
      const store = this.$store;
      const product = this.$route.params.product;
      let productDetailSelected = null;
      let categoryId = null;
      const productSlug = subStringProduct(product);
      const nameCategorySlug = productSlug[0];
      const productIdSelected = parseInt(productSlug[1]);

      if (this.$route.name === 'customer_design_edit') {
        const data = await CustomerRequest.getCustomerDesignDetail(
          productIdSelected
        );
        //Huy: active item is set here:
        productDetailSelected = data.data.response;
        categoryId = productDetailSelected.category.id;
      } else {
        categoryId = null;
        const productCategoryList = this.$store.state.app.productCategoryList;
        productCategoryList.forEach(element => {
          if (element?.slug === nameCategorySlug) {
            categoryId = element.id;
            return true;
          }
        });

        const data = await ProductRequest.getProductDetail(productIdSelected);
        productDetailSelected = data.data.response;
      }

      store.commit(
        `${moduleName}/${SET_ACTIVE_ITEM_BY_PRODUCT_ID}`,
        productDetailSelected
      );

      store.commit(
        `${moduleName}/${SET_CAN_SAVE_MORE_DESIGN}`,
        productDetailSelected?.can_save_more_design
      );

      if (productDetailSelected) {
        const defaultZoom = productDetailSelected.category.default_zoom;

        const defaultRatio = {
          defaultSelected: productDetailSelected.size.selected,
          name: productDetailSelected.size.name,
          ratioHeight: productDetailSelected.size.height,
          ratioWidth: productDetailSelected.size.width
        };

        const zoom = constants.ZOOM_PERCENTS.find(
          z => z.key === `${defaultZoom * 100}%`
        );
        const activeZoom = zoom ? zoom.value : 0;

        store.commit(`${moduleName}/${CHANGE_PRODUCT}`, {
          activeProductId: categoryId,
          activeRatio: defaultRatio,
          activeZoom
        });
      }
    },
    async initCategoryElement() {
      const store = this.$store;
      const {
        data: { response }
      } = await ProductRequest.getElements();

      store.commit(`designModule/${SET_APP_ELEMENT}`, response);
    },
    // common functions
    handleCanvasEvent: function() {
      const _this = this;
      const { ZOOM_PERCENTS } = constants;
      let minZoom = ZOOM_PERCENTS[0].value;
      let maxZoom = ZOOM_PERCENTS[ZOOM_PERCENTS.length - 1].value;
      this.globalCanvas.on({
        'mouse:wheel': function(opt) {
          if (opt.e.altKey) {
            // limit range canvas when zoom in/zoom out
            if (opt.e.deltaY > 0 && _this.zoomCount > minZoom) {
              _this.calcCanvasWithZoom(_this.zoomCount - 1);
            } else if (opt.e.deltaY < 0 && _this.zoomCount < maxZoom) {
              _this.calcCanvasWithZoom(_this.zoomCount + 1);
            }
          }
        },
        'object:modified': function(e) {
          const target = e.target;
          const obj = target.canvas.getActiveObject();
          const bound = target.canvas;
          const boundReact = target.getBoundingRect();

          let canvasWidth = target.canvas.width;
          let canvasHeight = target.canvas.height;

          let maxWidth = canvasWidth - boundReact.width;
          let maxHeight = canvasHeight - boundReact.height;
          // eslint-disable-next-line no-underscore-dangle

          // if object is too big ignore
          if (
            obj.currentHeight > bound.height ||
            obj.currentWidth > bound.width
          ) {
            return;
          }

          obj.setCoords();
          // top-left  corner
          if (boundReact.top < 0) {
            let turnTop = confirm(
              'Chữ của bạn có thể bị cắt trong quá trình in ấn'
            );
            if (turnTop == true) {
              return boundReact.top;
            } else {
              obj.set({ top: e.transform.lastY - e.transform.offsetY });
            }
          } else if (boundReact.top > maxHeight) {
            let turnBottom = confirm(
              'Chữ của bạn có thể bị cắt trong quá trình in ấn'
            );
            if (turnBottom == true) {
              return boundReact.top;
            } else {
              obj.set({ top: e.transform.lastY - e.transform.offsetY });
            }
          }
          // left-right corner
          if (boundReact.left < 0) {
            let turnLeft = confirm(
              'Chữ của bạn có thể bị cắt trong quá trình in ấn'
            );
            if (turnLeft == true) {
              return boundReact.left;
            } else {
              obj.set({ left: e.transform.lastX - e.transform.offsetX });
            }
          } else if (boundReact.left > maxWidth) {
            let turnRight = confirm(
              'Chữ của bạn có thể bị cắt trong quá trình in ấn'
            );
            if (turnRight == true) {
              return boundReact.left;
            } else {
              obj.set({ left: e.transform.lastX - e.transform.offsetX });
            }
          }

          // _this.isRedoDisabledBtn = true;
          // _this.handleUndo(_this);
        }
      });
    },

    resetCanvasToOriginal(canvas, zoomCount) {
      const scale = Math.pow(1 / constants.SCALE_RATIO, this.zoomCount);

      this.onScaleCanvas(canvas, scale);
    },
    onScaleCanvas: function(canvas, scaleRatio = null) {
      let scale = scaleRatio || Math.pow(constants.SCALE_RATIO, this.zoomCount);
      let zoomWidth = Math.round(canvas.getWidth() * scale);
      let zoomHeight = Math.round(canvas.getHeight() * scale);

      canvas.setWidth(zoomWidth);
      canvas.setHeight(zoomHeight);
      let objects = canvas.getObjects();
      for (let i in objects) {
        let scaleX = objects[i].scaleX;
        let scaleY = objects[i].scaleY;
        let left = objects[i].left;
        let top = objects[i].top;

        let tempScaleX = scaleX * scale;
        let tempScaleY = scaleY * scale;
        let tempLeft = left * scale;
        let tempTop = top * scale;

        objects[i].scaleX = tempScaleX;
        objects[i].scaleY = tempScaleY;
        objects[i].left = tempLeft;
        objects[i].top = tempTop;

        objects[i].setCoords();
      }

      canvas.requestRenderAll();
    },
    calcCanvasWithZoom: function(zoomCount) {
      const store = this.$store;
      const { canvasList } = store.state[moduleName];
      const totalCanvas = canvasList.length;

      for (let index = 0; index < totalCanvas; index++) {
        const { id, canvas } = canvasList[index];

        this.resetCanvasToOriginal(canvas, parseInt(zoomCount));

        const scale = Math.pow(constants.SCALE_RATIO, zoomCount);

        this.onScaleCanvas(canvas, scale);
      }

      this.zoomCount = parseInt(zoomCount);
    },
    recalculateOriginalCanvas: function(callback) {
      let zoom = this.zoomCount;
      this.calcCanvasWithZoom(0);

      callback && callback(this);
      this.$nextTick(function() {
        this.calcCanvasWithZoom(zoom);
      });
    },

    // handle submit order
    orderConfirm: function() {
      /**
       * TODO
       * Remove this function
       * (moved to Header component)
       **/
      this.recalculateOriginalCanvas(function(e) {
        e.imageExportInfo.json = JSON.stringify(e.globalCanvas);
        e.imageExportInfo.image = e.globalCanvas.toDataURL({
          enableRetinaScaling: true
        });
        e.imageExportInfo.imageOptimize = e.globalCanvas.toDataURL({
          format: 'jpeg',
          quality: 0.7
        });

        e.$refs.orderModal.show();
      });
    },
    dataURLtoFile: function(dataURL, fileName) {
      let arr = dataURL.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], fileName, { type: mime });
    },
    orderProcess: function(process) {
      let downloadLink = document.createElement('a');
      this[process](downloadLink);
      downloadLink.click();
      this.$nextTick(function() {
        this.$refs.orderModal.hide();
      });
    },
    openSendMailModal: function() {
      this.$refs.sendMailModal.show();
    },
    saveImage: function(downloadLink) {
      downloadLink.href = this.imageExportInfo.image;
      downloadLink.download = 'Enter you product name here.png';
    },
    exportJSON: function(downloadLink) {
      downloadLink.href =
        'data:application/json;charset=utf-8,' +
        encodeURIComponent(this.imageExportInfo.json);
      downloadLink.download = 'data.json';
    },
    exportSVG: function() {
      const canvas = this.globalCanvas;
      const svgDataURL = canvasHelpers.exportToSVGDataURL({
        canvas
      });

      const downloadLink = document.createElement('a');
      downloadLink.href = svgDataURL;
      downloadLink.setAttribute('download', 'output.svg');

      downloadLink.click();
    },

    // left
    handleRotateLeft: function() {
      canvasHelpers.centerCanvasRotate({
        canvas: this.globalCanvas,
        deg: -90
      });
    },
    // right
    handleRotateRight: function() {
      canvasHelpers.centerCanvasRotate({
        canvas: this.globalCanvas,
        deg: 90
      });
    },
    getKeyZoomWithValue(value) {
      return constants.ZOOM_PERCENTS.filter(function(e) {
        return e.value === value;
      })[0].key;
    }
  },
  created() {
    this.fetchAllAppData();

    this.categoryList = constants.CATEGORY_LIST;
    this.imageList = imgListData;
  },
  mounted() {
    eventBus.on('on-order-information-modal-send-mail', this.sendMail);
    // window.addEventListener('resize', this.forceCloseSidebarAndToolbar);
  },
  beforeDestroy() {
    eventBus.off('on-order-information-modal-send-mail', this.sendMail);
  },
  watch: {
    zoomCount(value) {
      this.selectedZoomPercent = value;
    },
    getCanvasList(value) {
      const filterValue = value.filter(
        v => v.canvas !== null && v.svgURL !== ''
      );
      if (value.length === filterValue.length) {
        for (let index = 0; index < value.length; index++) {
          const { id, canvas } = value[index];
          const scale = Math.pow(constants.SCALE_RATIO, this.zoomCount);
          this.onScaleCanvas(canvas, scale);
        }
      }
    }
  }
};
