import { mapGetters } from 'vuex';
import firebase from 'firebase';
import {
  BButton,
  BDropdown,
  BDropdownItemButton,
  BIcon,
  BTooltip,
  BImg
} from 'bootstrap-vue';
import { canvasHelpers } from '@/utils';
import { designIcons } from '@/utils/mediaData';
import { isPC, subStringProduct } from '@/utils/commonUtils';
import OrderModalPc from './Modals/Order/Pc/OrderModalPc.vue';
import OrderModalMb from './Modals/Order/Mobile/OrderModalMb.vue';
import UpComingPopover from '../../../components/UpComingPopover/UpComingPopover.vue';
import MaxQuotaPopover from '../../../components/MaxQuotaPopover/MaxQuotaPopover.vue';
import { RequestFactory } from '@Request/RequestFactory';
import { SET_CURRENT_CUSTOMER_DESIGN } from '@/store/designModule';
import { AUTO_SAVE_INTERVAL } from '@/utils/constants';
import * as Sentry from '@sentry/vue';

const moduleName = 'designModule';
const CustomerRequest = RequestFactory.get('customers');

const rightMenus = [
  {
    icon: designIcons.printIcon,
    text: 'Gửi in'
  },
  {
    icon: designIcons.downloadIcon,
    text: 'Tải xuống'
  },
  {
    icon: designIcons.loginIcon,
    text: 'Đăng nhập'
  },
  {
    icon: designIcons.saveIcon,
    text: 'Lưu thiết kế'
  }
];

export default {
  name: 'Header',
  components: {
    BButton,
    BDropdown,
    BDropdownItemButton,
    OrderModalPc,
    OrderModalMb,
    UpComingPopover,
    MaxQuotaPopover,
    BIcon,
    BTooltip,
    BImg
  },
  data() {
    return {
      logo: designIcons.logo,
      login: designIcons.loginIcon,
      homeIcon: designIcons.homeIcon,
      shareIcon: designIcons.shareIcon,
      download: designIcons.downloadIcon,
      saveIcon: designIcons.saveIcon,
      saveLoader: false,
      rightMenus,
      isPC
    };
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
      localStorage.removeItem('userToken');
      this.$router.push({ name: 'home' });
    },
    exportAllCanvasesToImage() {
      const store = this.$store;
      const { canvasList } = store.state[moduleName];

      const totalCanvases = canvasList.length;

      for (let i = 0; i < totalCanvases; i++) {
        const { id, canvas } = canvasList[i];
        const image = canvas.toDataURL({
          enableRetinaScaling: true
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = `${id}.png`;

        downloadLink.click();
      }
    },
    exportSVG() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];
      const svgDataURL = canvasHelpers.exportToSVGDataURL({
        canvas: activeCanvas
      });

      const downloadLink = document.createElement('a');
      downloadLink.href = svgDataURL;
      downloadLink.setAttribute('download', 'output.svg');

      downloadLink.click();
    },
    exportJSON() {
      const store = this.$store;
      const { activeCanvas } = store.state[moduleName];

      if (!activeCanvas) {
        return;
      }

      const json = JSON.stringify(activeCanvas);
      const downloadLink = document.createElement('a');
      downloadLink.href =
        'data:application/json;charset=utf-8,' + encodeURIComponent(json);
      downloadLink.download = 'data.json';

      downloadLink.click();
    },
    saveDesign() {
      const store = this.$store;
      const {
        canvasList,
        currentCustomerDesignId,
        canSaveMoreDesign
      } = store.state[moduleName];
      const totalCanvases = canvasList.length;
      const product = this.$route.params.product;
      const productSlug = subStringProduct(product);
      let designId = currentCustomerDesignId;
      let name = productSlug[0];

      if (!canSaveMoreDesign) {
        this.$refs.maxQuotaPopover.$emit('open');
        return;
      }

      if (this.$route.name === 'customer_design_edit') {
        designId = parseInt(productSlug[1]);
      }

      const formData = {
        designId,
        name,
        products: []
      };

      for (let i = 0; i < totalCanvases; i++) {
        const { canvas, templateId } = canvasList[i];
        const svgDataURL = canvasHelpers.exportToSVGDataURL({ canvas });
        const jsonDesign = JSON.stringify(canvas);

        let productItem = {
          productDetailId: templateId,
          json: jsonDesign,
          svg: svgDataURL,
          thumbnail: canvas.toDataURL(),
          currentWidth: canvas.getWidth(),
          currentHeight: canvas.getHeight()
        };

        formData.products = [...formData.products, productItem];
      }

      const _this = this;
      _this.saveLoader = true;

      try {
        CustomerRequest.createOrUpdateCustomerDesign(formData)
          .then(function({ data }) {
            const { response } = data;
            designId = response.id;
            store.commit(
              `${moduleName}/${SET_CURRENT_CUSTOMER_DESIGN}`,
              response.id
            );
            _this.saveLoader = false;
          })
          .catch(function() {
            const error =
              'Lưu thiết kế lỗi, xin liên hệ Nitpen để được hỗ trợ!';
            _this.saveLoader = false;
            _this.$toastr.e(error);
          });
      } catch (error) {
        _this.saveLoader = false;
        Sentry.captureException(error);
      }
    }
  },
  created() {
    const _this = this;
    setInterval(function() {
      _this.saveDesign();
    }, AUTO_SAVE_INTERVAL); // 5m
  }
};
