var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"design-page"},[_c('HeaderPc'),_c('b-container',{staticClass:"main-container overflow-hidden",class:{ pc: _vm.isHorizontalScreen(_vm.$store) },attrs:{"fluid":""}},[_c('b-row',{staticClass:"h-100"},[(_vm.isHorizontalScreen(_vm.$store))?_c('MainToolbarPc',{on:{"onOpenSidebar":function($event){return _vm.onOpenSidebar($event)},"onHideMainToolbar":function($event){return _vm.onHideMainToolbar($event)}}}):_vm._e(),_c('div',{staticClass:"p-0 h-100 main-design-area",class:{
          'on-open-sidebar': _vm.isOpenSidebar && _vm.isPC(_vm.$store),
          'on-close-main-toolbar': _vm.isHideMainToolbar,
          col: !_vm.isOpenSidebar || !_vm.isPC(_vm.$store),
          tablet: _vm.isTablet(_vm.$store),
          pc: _vm.isHorizontalScreen(_vm.$store)
        }},[_c('ActionToolbarMb'),_c('div',{staticClass:"design-area overflow-auto",class:{
            pc: _vm.isHorizontalScreen(_vm.$store),
            'design-area-edit': _vm.getStatusEditCanvas,
            'custom-scrollbar': !_vm.isTablet(_vm.$store),
            'on-close-main-toolbar': _vm.isHideMainToolbar
          },attrs:{"id":"draw2"}},[(_vm.isShowMainDesignArea(_vm.$store))?_c('MainDesignArea'):_vm._e()],1),_c('div',{staticClass:"position-absolute zoom-input"},[(_vm.isPC(_vm.$store))?_c('b-form-select',{on:{"change":function($event){return _vm.calcCanvasWithZoom($event)}},model:{value:(_vm.selectedZoomPercent),callback:function ($$v) {_vm.selectedZoomPercent=$$v},expression:"selectedZoomPercent"}},_vm._l((_vm.constants.ZOOM_PERCENTS),function(option){return _c('option',{key:option.key,domProps:{"value":option.value,"textContent":_vm._s(option.key)}})}),0):_vm._e(),(_vm.isMobile(_vm.$store))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getStatusEditCanvas),expression:"!getStatusEditCanvas"}]},[_c('b-form-input',{attrs:{"id":"range-1","type":"range","min":_vm.constants.ZOOM_PERCENTS[0].value,"max":_vm.constants.ZOOM_PERCENTS[_vm.constants.ZOOM_PERCENTS.length - 1]
                  .value,"step":"1"},on:{"change":function($event){return _vm.calcCanvasWithZoom($event)}},model:{value:(_vm.selectedZoomPercent),callback:function ($$v) {_vm.selectedZoomPercent=$$v},expression:"selectedZoomPercent"}}),_c('div',[_vm._v("Value: "+_vm._s(_vm.getKeyZoomWithValue(_vm.selectedZoomPercent)))])],1):_vm._e()],1)],1)],1)],1),(!_vm.isHorizontalScreen(_vm.$store))?_c('MainToolbarMb',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getStatusEditCanvas),expression:"!getStatusEditCanvas"}],on:{"onOpenSidebar":function($event){return _vm.onOpenSidebar($event)},"onHideMainToolbar":function($event){return _vm.onHideMainToolbar($event)}}}):_vm._e(),_c('SupportBlock',{directives:[{name:"show",rawName:"v-show",value:((!_vm.getStatusEditCanvas && _vm.isMobile(_vm.$store)) || !_vm.isMobile(_vm.$store)),expression:"(!getStatusEditCanvas && isMobile($store)) || !isMobile($store)"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }